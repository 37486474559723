$gray: #858585;
$danger: #d90000;
$success: #69be46;

.alert {
  border: 1px solid $gray;
  background: rgba($gray, 0.05);
  color: $gray;
  padding: 1rem;

  &.small {
    padding: 0.5rem;
  }

  &.danger {
    color: $danger;
    background-color: rgba($danger, 0.05);
    border-color: $danger;
  }

  &.info {
    color: #0c5460;
    background-color: #d1ecf1;
    border-color: #bee5eb;
  }

  &.success {
    color: $success;
    background-color: rgba($success, 0.08);
    border-color: $success;
  }

  &.warning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
  }
}
