@mixin flow-theme-button-toggle($theme) {
  html {
    --mat-standard-button-toggle-label-text-size: 14.4px;
    --mat-standard-button-toggle-height: 36px;
    --mat-standard-button-toggle-label-text-weight: 300;
    --mat-standard-button-toggle-selected-state-background-color: var(--white);
    --mat-standard-button-toggle-selected-state-text-color: var(--white);
    --mat-standard-button-toggle-selected-state-background-color: var(--primary);
  }
}
