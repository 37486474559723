.timepicker-header {
  background-color: var(--primary) !important;
  color: rgba(255, 255, 255, .5) !important;
}

.clock-face__number {
  > span.active {
    background-color: var(--primary) !important;
  }
}

.timepicker-dial__control_editable {
  &:focus {
    color: var(--primary) !important;
    outline: var(--primary) !important;
  }

  &.active {
    background-color: var(--white) !important;
    color: var(--primary) !important;
  }
}

.clock-face__clock-hand {
  background-color: var(--primary) !important;
}
