@use "../functions" as *;
@use "../mixins" as *;

@charset "UTF-8";

@mixin flow-theme-modals($theme) {
  html {
    --mdc-dialog-supporting-text-color: var(--black);
    --mdc-dialog-supporting-text-size: rem(14);
    --mdc-dialog-supporting-text-line-height: rem(20);
    --mat-dialog-actions-alignment: flex-end;
    --mat-dialog-actions-padding: 0px 24px 10px;
  }

  .mdc-dialog__title {
    margin: 0 0 20px !important;
  }

  .modal-title {
    background: var(--primary);
    color: var(--white);

    h5 {
      color: var(--white);
    }
  }

  .mat-mdc-dialog-title {
    &.with-description {
      margin: 0 !important;
      padding-bottom: 0 !important;

      +div {
        padding-left: 24px;
      }
    }
  }

  flow-modal-simple-text {
    .mat-mdc-dialog-content {
      ul,
      ol {
        margin-left: 1em;
      }

      h6 {
        margin-bottom: 1em;
        font-weight: bold;
      }
    }
  }

  flow-modal-simple-text:not(.no-bottom-margin) .mat-mdc-dialog-content {
    p,
    ul,
    ol {
      margin-bottom: 1em;
    }
  }

  .flow-quill-dialog,
  .flow-text-dialog {
    .mat-mdc-dialog-title {
      margin: 0;
    }
  }

  .no-inner-padding-modal {
    .mat-mdc-dialog-content {
      padding: 0 !important;
    }

    .mat-mdc-dialog-actions {
      margin-bottom: 0;
      padding: 0 15px !important;
    }
  }

  .flow-quill-dialog {
    quill-editor {
      display: flex;
      flex-direction: column;
      height: 100%;
      position: relative;
    }

    .editor-wrapper {
      max-height: 100%;
      flex: 1 0 auto;
      height: calc(100% - 52px);
      overflow: hidden;
    }

    .ql-container {
      height: calc(100% - 42px);
      position: relative;
      display: flex;
      flex-direction: column;
    }
  }

  .cdk-overlay-backdrop.flow-modal-backdrop {
    background: rgba(0,0,0, .60);
  }

  .cdk-global-overlay-wrapper {
    .cdk-overlay-pane {
      &.flow-sidenav-modal {
        flex-direction: column;

        .flow-sidenav-modal-wrap {
          @include dflex(1, 0, auto);
          @include boxShadow(-5px 0px 5px 0px rgba(0, 0, 0, 0.27));
          @include animation(slideLeft, 500ms);

          background: var(--white);
          flex-direction: column;
          position: relative;
        }

        &.closing {
          .flow-sidenav-modal-wrap {
            @include animation(slideRight, 500ms);
          }
        }
      }
    }
  }
}
