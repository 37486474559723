@use "sass:map";
@use "breakpoints" as *;
@use "variables" as *;

@charset "UTF-8";

@use "sass:math";

@mixin make-grid-columns($columns: $grid-columns, $gutter: $grid-gutter-width, $breakpoints: $grid-breakpoints) {
  %grid-column {
    position: relative;
    width: 100%;
    min-height: 1px; // Prevent columns from collapsing when empty
    padding-right: ($gutter * 0.5);
    padding-left: ($gutter * 0.5);
  }

  @each $breakpoint in map.keys($breakpoints) {
    $infix: breakpoint-infix($breakpoint, $breakpoints);

    // Allow columns to stretch full width below their breakpoints
    @for $i from 1 through $columns {
      .col#{$infix}-#{$i} {
        @extend %grid-column;
      }
    }
    .col#{$infix},
    .col#{$infix}-auto {
      @extend %grid-column;
    }

    @include media-breakpoint-up($breakpoint, $breakpoints) {
      // Provide basic `.col-{bp}` classes for equal-width flexbox columns
      .col#{$infix} {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
      }
      .col#{$infix}-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: none; // Reset earlier grid tiers
      }

      @for $i from 1 through $columns {
        .col#{$infix}-#{$i} {
          @include make-col($i, $columns);
        }
      }
    }
  }
}

@mixin make-container() {
  width: 100%;
  max-width: 1280px;
  padding-right: ($grid-gutter-width * 0.5);
  padding-left: ($grid-gutter-width * 0.5);
  margin-right: auto;
  margin-left: auto;
}


@mixin make-container-max-widths($max-widths: $container-max-widths, $breakpoints: $grid-breakpoints) {
  @each $breakpoint, $container-max-width in $max-widths {
    @include media-breakpoint-up($breakpoint, $breakpoints) {
      max-width: $container-max-width;
    }
  }
}

@mixin make-row() {
  display: flex;
  flex-wrap: wrap;
  margin-right: math.div($grid-gutter-width, -2);
  margin-left: math.div($grid-gutter-width, -2);
}

@mixin make-col-ready() {
  position: relative;
  width: 100%;
  min-height: 1px; // Prevent collapsing
  padding-right: ($grid-gutter-width * 0.5);
  padding-left: ($grid-gutter-width * 0.5);
}

@mixin make-col($size, $columns: $grid-columns) {
  flex: 0 0 math.percentage(math.div($size, $columns));
  max-width: math.percentage(math.div($size, $columns));
}

.container {
  @include make-container();
}

.container-fluid {
  @include make-container();
}

.row {
  @include make-row();
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;

  > .col,
  > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}

.row-half-gutter {
  [class*="col-"] {
    padding-left: 7.5px;
    padding-right: 7.5px;
  }
}

.row-double-gutter {
  [class*="col-"] {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@include make-grid-columns();

@each $breakpoint in map.keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $position in $block-positions {
      .pos#{$infix}-#{$position} {
        position: #{$position};
      }
    }

    @each $size in $percentages {
      .w#{$infix}-#{$size} {
        width: math.percentage($size*0.01);
      }
    }

    @each $align in $text-aligns {
      .text#{$infix}-#{$align} {
        text-align: #{$align};
      }
    }

    @each $align in (left, right) {
      .pull#{$infix}-#{$align} {
        float: #{$align} !important;
      }
    }

    @each $prop, $abbrev in (margin: m, padding: p) {
      @each $size, $length in $spacers {

        .#{$abbrev}#{$infix}-#{$size} {
          #{$prop}: $length !important;
        }
        .#{$abbrev}t#{$infix}-#{$size},
        .#{$abbrev}y#{$infix}-#{$size} {
          #{$prop}-top: $length !important;;
        }
        .#{$abbrev}r#{$infix}-#{$size},
        .#{$abbrev}x#{$infix}-#{$size} {
          #{$prop}-right: $length !important;;
        }
        .#{$abbrev}b#{$infix}-#{$size},
        .#{$abbrev}y#{$infix}-#{$size} {
          #{$prop}-bottom: $length !important;;
        }
        .#{$abbrev}l#{$infix}-#{$size},
        .#{$abbrev}x#{$infix}-#{$size} {
          #{$prop}-left: $length !important;;
        }
      }
    }

    .d#{$infix}-none {
      display: none !important;
    }
    .d#{$infix}-inline-block {
      display: inline-block !important;
    }
    .d#{$infix}-block {
      display: block !important;
    }
    .d#{$infix}-flex {
      display: flex !important;
    }
    .d#{$infix}-inline-flex {
      display: inline-flex !important;
    }

    .flex#{$infix}-row {
      flex-direction: row !important;
    }
    .flex#{$infix}-column {
      flex-direction: column !important;
    }
    .flex#{$infix}-row-reverse {
      flex-direction: row-reverse !important;
    }
    .flex#{$infix}-column-reverse {
      flex-direction: column-reverse !important;
    }

    .justify-content#{$infix}-start {
      justify-content: flex-start !important;
    }
    .justify-content#{$infix}-end {
      justify-content: flex-end !important;
    }
    .justify-content#{$infix}-center {
      justify-content: center !important;
    }
    .justify-content#{$infix}-between {
      justify-content: space-between !important;
    }

    .align-items#{$infix}-start {
      align-items: flex-start !important;
    }
    .align-items#{$infix}-end {
      align-items: flex-end !important;
    }
    .align-items#{$infix}-center {
      align-items: center !important;
    }
    .align-items#{$infix}-baseline {
      align-items: baseline !important;
    }
  }
}

