@mixin flow-theme-radios($theme) {
  .mat-mdc-radio-button {
    &.mat-accent {
      --mdc-radio-selected-focus-icon-color: var(--primary);
      --mdc-radio-selected-hover-icon-color: var(--primary);
      --mdc-radio-unselected-hover-icon-color: var(--primary);
      --mdc-radio-selected-icon-color: var(--primary);
      --mdc-radio-selected-pressed-icon-color: var(--primary);
      --mat-radio-checked-ripple-color: transparent;
      --mat-radio-ripple-color: transparent;
    }

    .mdc-label {
      color: var(--text);
      cursor: pointer;
    }
  }
}
