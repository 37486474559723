@use "sass:map";

@use "@angular/material" as mat;

@mixin flow-theme-accordions($theme) {
  html {
    --mat-expansion-header-text-size: 15px;
    --mat-expansion-header-text-weight: 400;
  }

  $elevation-color: mat.get-theme-color($theme, 'foreground', 'elevation');
  $elevation-color-or-default: if($elevation-color == null, elevation.$color, $elevation-color);

  .mat-expansion-panel {
    @include mat.expansion-density($theme);
    @include mat.overridable-elevation(2, $elevation-color-or-default);

    background: mat.get-theme-color($theme, 'background', 'card');
    color: var(--black);

    &.overflow-visible {
      overflow: visible;
    }
  }

  .mat-action-row {
    border-top-color: mat.get-theme-color($theme, 'foreground', 'divider');
  }

  .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header {
    &:not([aria-disabled='true']) {
      &.cdk-keyboard-focused,
      &.cdk-program-focused,
      &:hover {
        background: mat.get-theme-color($theme, 'background', 'hover');
      }
    }
  }

  // Disable the hover on touch devices since it can appear like it is stuck. We can't use
  // `@media (hover)` above, because the desktop support browser support isn't great.
  @media (hover: none) {
    .mat-expansion-panel:not(.mat-expanded):not([aria-disabled='true'])
    .mat-expansion-panel-header:hover {
      background: mat.get-theme-color($theme, 'background', 'card');
    }
  }

  .mat-expansion-panel-header-title {
    color: var(--black);
  }

  .mat-expansion-panel-header-description,
  .mat-expansion-indicator::after {
    color: mat.get-theme-color($theme, 'foreground', 'secondary-text');
  }

  .mat-expansion-panel-header[aria-disabled='true'] {
    color: mat.get-theme-color($theme, 'foreground', 'disabled-button');

    .mat-expansion-panel-header-title,
    .mat-expansion-panel-header-description {
      color: inherit;
    }
  }
}
