@use "sass:map";
@use "functions" as *;
@use "variables" as *;

// TEMPORARY FOR TESTING: CAN BE REMOVED LATER
@mixin input-placeholder {
  &::placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &::-webkit-input-placeholder {
    @content;
  }

  // Note: this isn't necessary anymore since we don't support
  // IE, but it caused some presubmit failures in #23416.
  &:-ms-input-placeholder {
    @content;
  }
}


@mixin flexbox {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

@mixin flex-grow($int: 1) {
  -webkit-box-flex: $int;
  -moz-box-flex: $int;
  -webkit-flex-grow: $int;
  -ms-flex: $int;
  flex-grow: $int;
}

@mixin flex-shrink($int: 0) {
  -webkit-flex-shrink: $int;
  -moz-flex-shrink: $int;
  -ms-flex: $int;
  flex-shrink: $int;
}

@mixin flex-basis($value: auto) {
  -webkit-flex-basis: $value;
  flex-basis: $value;
}

@mixin dflex($grow: 1, $shrink: 0, $basis: auto ) {
  @include flexbox();
  @include flex-grow($grow);
  @include flex-shrink($shrink);
  @include flex-basis($basis);
}

@mixin align-items($value: center) {
  @if $value == flex-start {
    -webkit-box-align: start;
    -moz-box-align: start;
    -ms-flex-align: start;
  } @else if $value == flex-end {
    -webkit-box-align: end;
    -moz-box-align: end;
    -ms-flex-align: end;
  } @else {
    -webkit-box-align: $value;
    -moz-box-align: $value;
    -ms-flex-align: $value;
  }
  -webkit-align-items: $value;
  align-items: $value;
}


@mixin background($repeat: no-repeat, $mode: cover, $position: center center ) {
  @include backgroundSize($mode);
  background-repeat: $repeat;
  background-position: $position;
}

@mixin backgroundSize($mode: cover ) {
  background-size: $mode;
  -webkit-background-size: $mode;
  -moz-background-size: $mode;
}

@mixin boxShadow( $shadow... ) {
       box-shadow: $shadow;
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
}

@mixin borderRadius( $amount: 50% ) {
  border-radius: $amount;
  -webkit-border-radius: $amount;
  -moz-border-radius: $amount;
}

@mixin transition($property: all, $time: 300ms, $ease: ease-in) {
  -webkit-transition: $property $time $ease;
  -moz-transition: $property $time $ease;
  -o-transition: $property $time $ease;
  -ms-transition: $property $time $ease;
  transition: $property $time $ease;
}

@mixin transition-delay($delay...) {
  -moz-transition-delay:    $delay;
  -o-transition-delay:      $delay;
  -webkit-transition-delay: $delay;
  transition-delay:         $delay;
}

@mixin transform ($val) {
  -webkit-transform: $val;
  -moz-transform: $val;
  -ms-transform: $val;
  transform: $val;
}

@mixin translate($x: 0, $y:0 ){
  -webkit-transform: translate($x, $y);
  -moz-transform: translate($x, $y);
  -ms-transform: translate($x, $y);
  -o-transform: translate($x, $y);
  transform: translate($x, $y);
}

@mixin transform-origin ($val) {
  -webkit-transform-origin: $val;
  -moz-transform-origin: $val;
  -ms-transform-origin: $val;
  transform-origin: $val;
}

@mixin opacity ($trans) {
  filter: alpha(opacity=($trans * 100));
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=#{$trans * 100})";
  -moz-opacity: $trans;
  -khtml-opacity: $trans;
  opacity: $trans;
}

@mixin animation($animation: fadeIn, $time: 1s) {
  animation: $animation $time;
  -moz-animation: $animation $time;
  -webkit-animation: $animation $time;
  -o-animation: $animation $time;
}

@mixin base-effects () {
  @include opacity(0);
  pointer-events: none;
  @include transition(all, .18s, ease-out .18s);
}

@mixin no-effects () {
  @include transition(none);
}

@mixin content-before {
  &:before,
  &:after {
    display: table;
    content: " ";
  }

  &:after {
    clear: both;
  }
}

@mixin flow-schemas-colors() {
  @each $color, $variants in $colors {
    @each $variant, $vColor in $variants {

      @if $variant == 100 {
        .color-#{"" + $color} {
          color: $vColor;
        }
        .bg-#{"" + $color} {
          background-color: $vColor;
        }
        .color-#{"" + $color}-link {
          color: $vColor;
          &:hover {
            text-decoration: underline;
          }
        }
      } @else {
        .color-#{"" + $color}-#{$variant} {
          color: $vColor;
        }
        .bg-#{"" + $color}-#{$variant} {
          background-color: $vColor;
        }
      }
    }

    @if( $color == 'tpsa' or $color == 'tpma' or $color == 'tpia' or $color == 'tpao') {
      .bg-#{$color}-hover:hover {
        background-color: map.get($variants, 20 ) !important;
      }
    }
  }
}

@mixin flow-font-sizes() {
  @each $value in $font-sizes {
    .fs-#{$value} {
      font-size: rem($value);
    }
  }
}


@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }

  @-moz-keyframes #{$name} {
    @content;
  }

  @-ms-keyframes #{$name} {
    @content;
  }

  @keyframes #{$name} {
    @content;
  }
}
