@use "sass:map";
@use "../breakpoints" as *;
@use "../functions" as *;
@use "../mixins" as *;

/**
 * Flow mixin for buttons
 *
 * Follow the same approach than the bootstrap framework.
 * .button is the same class
 * then, based on our colors, the different predefined classes are created.
 * also, the current material primary color is appended.
 */
@mixin flow-theme-buttons($theme) {

  $color: () !default;

  $colors: (
    primary: (
            100: var(--primary),
            hover: var(--primary-hover)
    ),
    danger: (
            100: var(--danger)
    ),
    success: (
            100: var(--success)
    ),
    white: (
            100: var(--white)
    )
  );

  $base-height: 39px;
  $base-small-height: 32px;

  .button {
    @include transition(all, 200ms);

    font-family: 'Roboto', sans-serif;;
    background: transparent;
    color: inherit;
    display: inline-block;
    outline: none;
    float: none;
    cursor: pointer;
    width: auto;
    line-height: normal;
    padding: 0.6rem 1.2rem;
    font-size: .85rem;
    border: 1px solid transparent;

    .material-icons {
      vertical-align: middle;
      height: auto;
      width: auto;
      font-size: rem(18);
      padding: 0 10px 2px 0;
    }

    &.button-rounded {
      @include borderRadius(4px);
    }

    &.button-block {
      width: 100%;
      max-width: none;
    }

    // Buttons above datatables without icon
    &.button-datatable-no-icon {
      padding: 0.88rem 1.2rem 0.91rem 1.2rem;
    }

    // Change label of back button above datatables on small screens
    &.button-datatable-back {
      @include media-breakpoint-down(sm) {
        span {
          display: none;
        }

        &:before {
          content: "<";
        }
      }
    }

    // Buttons in help area
    &.button-help-area {
      background-color: var(--help-btn-bg-color);
      border-color: var(--help-btn-border-color);
      color: var(--help-btn-color);

      &:not(:disabled):hover {
        background: var(--help-btn-bg-color-hover);
        color: var(--help-btn-color-hover);
      }
    }

    // Icon buttons.
    &.button-icon {
      @include borderRadius();
      width: $base-small-height;
      height: $base-small-height;
      line-height: $base-small-height;
      padding: 0;
      opacity: 1;
      text-align: center;
      //@include boxShadow(0 1px 3px $light-border);

      .material-icons {
        line-height: $base-small-height;
        padding: 0;
        font-size: rem(18);

        &.back {
          font-size: rem(20);
          padding: 0 1px 2px 0;
        }
      }

      &:focus,
      &:hover {
        background: rgba(10, 10, 10, .06);
      }

      &:disabled {
        background: none;
      }
    }

    @each $color,
    $variants in $colors {

      // Default styles.
      &.button-#{#{$color}} {
        background-color: map.get($variants, 100);
        border-color: map.get($variants, 100 );

        @if( $color == white ) {
          color: var(--primary);
        } @else {
          color: var(--white);
        }

        @if ( $color == primary) {
          &:not(:disabled):hover {
            background: var(--primary-hover);
            border-color: var(--primary-hover);
          }
        } @else {
          &:not(:disabled):hover {
            opacity: .9;
          }
        }

        &-outline {
          background-color: transparent;
          border-color: map.get($variants, 100 );
          color: map.get($variants, 100 );

          &:not(:disabled):hover {
            background: map.get($variants, 100 );
            @if( $color == white ) {
              color: var(--primary);
            } @else {
              color: var(--white);
            }
          }
        }
      }

      &.button-icon-#{#{$color}} {
        color: map.get($variants, 100 );
      }
    }

    // Disabled style
    &:disabled {
      cursor: not-allowed;
      background-color: #e0e0e0;
      color: var(--text);
      border: 1px solid transparent;
    }

    // Overriding classes
    &.button-no-border {
      border: 0 !important;
    }

    &.button-close-dialogs {
      color: var(--black);
      padding-left: 0;

      &:hover {
        color: var(--danger);
      }
    }

    // add the different sizes.
    // @todo.
    &.button-small {
      font-size: .75rem;
      padding: .5rem .75rem;
      height: auto;
      line-height: normal;

      &.button-icon {
        width: $base-small-height;
        height: $base-small-height;
        padding: 0;

        .material-icons {
          line-height: $base-small-height;
          font-size: rem(14);
        }
      }
    }
  }
}
