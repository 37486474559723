@use "../functions" as *;
@use "../variables" as *;

@mixin flow-theme-lists($theme) {
  .list-icons {
    > li {
      padding-top: 8px;
      padding-bottom: 8px;
      border-bottom: 1px solid $light-border;
      &:last-child {
        border-bottom: 0;
      }
    }
  }

  .list-icons {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      .list-icon__content {
        list-style: none;
        padding: 0;
        margin: 0;
        li {
          font-size: rem(12);
        }
      }

      small {
        font-size: rem(11);
        margin-right: 6px;
        font-weight: 500;
      }
    }
  }

  .list-item-with-arrow {
    border: 1px solid #E3E3E3;
    border-radius: 3px;
    color: #535353;
    cursor: pointer;
    display: flex;
    font-weight: 400;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    min-height: 48px;
    padding: 8px 16px;
    text-align: left;

    &:hover {
      background-color: var(--table-hover-bg-color);
    }

    .item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .title {
        font-size: 1.0625rem;
      }

      small {
        font-size: rem(12);
        font-weight: 500;
      }
    }
  }
}
