@use "functions" as *;
@use "mixins" as *;


/////// @font-face mixin
// *-------------------------------------------------------------------------------
$webfont_font_family: 'flow-icons' !default;
$webfont-file: 'flow-icons' !default;
$webfont_weight: normal !default;
$webfont_style: normal !default;
$webfont_directory: "../fonts/flow-icons/" !default;


@mixin font-face($font_family: $webfont_font_family, $font-file: $webfont-file, $font_weight: $webfont_weight, $font_style: $webfont_style, $webfont_directory: $webfont_directory ) {
  @font-face {
    font-family: '#{$font_family}';
    font-style: $font_style;
    font-weight: $font_weight;
    src: url('#{$webfont_directory}#{$font-file}.eot');
    src: url('#{$webfont_directory}#{$font-file}.eot?#iefix') format('embedded-opentype'),
    url('#{$webfont_directory}#{$font-file}.woff2') format('woff2'),
    url('#{$webfont_directory}#{$font-file}.woff') format('woff'),
    url('#{$webfont_directory}#{$font-file}.ttf') format('truetype');
  }
}

$icon_font_alpha: 'flow-icons' !default;
$default_ico_margin: false !default;


/////// ico-fonts
// *-------------------------------------------------------------------------------
%ico-font-base {
  font-family: $icon_font_alpha;
  speak: none;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin icon($pua, $margin: $default_ico_margin) {
  &:before {
    @extend %ico-font-base;
    content: $pua;
    @if $margin == true {
      margin-right: 0.25em;
    }
  }
}

.flow-icon {
  display: inline-block;
  line-height: normal;
  width: auto;
  height: auto;

  @include icon('\0041');
}

@include font-face();

.icon-tpma {
  @include icon('\0031');
}
.icon-tpsa {
  @include icon('\0032');
}
.icon-tpia {
  @include icon('\0033');
}
.icon-tpoa {
  @include icon('\0034');
}

.icon-dashboard {
  @include icon('\0035');
  font-size: rem(16);
}
.icon-settings {
  @include icon('\0036');
  font-size: rem(17);
}

.icon-support {
  @include icon('\0039');
  font-size: rem(16);
}
.icon-search {
  @include icon('\0037');
  font-size: rem(20);
}
.icon-logout {
  @include icon('\0038');
  font-size: rem(16);
}

/* TPMA */
.icon-shosy {
  @include icon('\0041');
}
.icon-prosy {
  @include icon('\0042');
}
.icon-solsy {
  @include icon('\0043');
}
.icon-asssy {
  @include icon('\0044');
}
.icon-marco {
  @include icon('\0045');
}
.icon-emaca {
  @include icon('\0046');
}
.icon-socsy {
  @include icon('\0047');
}
.icon-ppcca {
  @include icon('\0048');
}
.icon-event {
  @include icon('\0049');
}
.icon-parlo {
  @include icon('\004A');
}
.icon-camau {
  @include icon('\004B');
}
.icon-campr {
  @include icon('\004C');
}

/* TPSA */
.icon-leaqu {
  @include icon('\004D');
}
.icon-leadi {
  @include icon('\004E');
}
.icon-leama {
  @include icon('\004F');
}
.icon-sarec {
  @include icon('\0050');
}
.icon-crm {
  @include icon('\0051');
}
.icon-bubus {
  @include icon('\0052');
}
.icon-inlineadmin,
.icon-inlsy {
  @include icon('\0053');
}
.icon-exppr {
  @include icon('\0054');
}
.icon-upssy {
  @include icon('\0055');
}
.icon-websh {
  @include icon('\0056');
}
.icon-syshop {
  @include icon('\0071');
}
.icon-leadgen {
  @include icon('\0079');
}

.icon-bulkoperations,
.icon-pricingrules,
.icon-rules-engine {
  @include icon('\00E1');
}

.icon-ediconditiongoups,
.icon-config-condition-groups {
  @include icon('\00E0');
}

.icon-edicross,
.icon-config-cross-references {
  @include icon('\00E4');
}
/* TPIA */
.icon-valid {
  @include icon('\0057');
}
.icon-edi {
  @include icon('\0058');
}

.icon-invoiceentry,
.icon-ezinvoic,
.icon-einvo,
.icon-einv2 {
  @include icon('\0059');
}
.icon-smpdf {
  @include icon('\005A');
}
.icon-edipe {
  @include icon('\0061');
}
.icon-einvp {
  @include icon('\0062');
}
.icon-atrpac,
.icon-partnerrelations,
.icon-trpac {
  @include icon('\0063');
}
.icon-earch {
  @include icon('\0064');
}
.icon-sysconshop,
.icon-sysco {
  @include icon('\0065');
}

.icon-edivalid,
.icon-config-validations {
  @include icon('\00E3');
}
.icon-edipendingdocuments,
.icon-docs{
  @include icon('\00E7');
}

/* TPOA */
.icon-optim {
  @include icon('\0066');
}
.icon-searc {
  @include icon('\0067');
}
.icon-goana {
  @include icon('\0068');
}
.icon-optre {
  @include icon('\0069');
}

.icon-pdf2flowanaly,
.icon-intanaly,
.icon-analy {
  @include icon('\006A');
}

.icon-busin {
  @include icon('\006B');
}
.icon-dataw {
  @include icon('\006C');
}

/* ADMIN */
.icon-assetadm {
  @include icon('\0072');
}
.icon-emailadm {
  @include icon('\0073');
}
.icon-socialadm {
  @include icon('\0074');
}
.icon-showcaseadm {
  @include icon('\0075');
}
.icon-partneradm {
  @include icon('\0076');
}
.icon-promoadm {
  @include icon('\0077');
}

.icon-proser {
  @include icon('\006D');
}
.icon-suppo {
  @include icon('\006E');
}

.icon-googs {
  @include icon('\006F');
}
.icon-vmktplc,
.icon-market {
  @include icon('\0070');
}

.icon-camdash,
.icon-business-planning {
  @include icon('\00E9');
}
.icon-workfl,
.icon-config-workflows {
  @include icon('\00E8');
}

.icon-validupload,
.icon-upload-file {
  @include icon('\007A');
}

.icon-asysco,
.icon-activated-system-connectors {
  @include icon('\00E5');
}

.icon-partners-community {
  @include icon('\00DC');
}


/////// standalone icons
// *-------------------------------------------------------------------------------
.flow-vendor-icon {
  background-image: url('../images/vendor-icon.svg');
  @include background(no-repeat, contain, center center);
}

.flow-partner-icon {
  background-image: url('../images/partner-icon.svg');
  @include background(no-repeat, contain, center center);
}
