@use "sass:map";
@use "variables" as *;

@use "sass:math";

@function breakpointSize($size) {
  @if map.has-key($grid-breakpoints, $size ) {
    @return map.get($grid-breakpoints, $size);
  }

  @error 'Map `grid-breakpoints` does not have the `#{$size}` key';
}

@function fcolor($color: blue, $hue: 100 ) {
  @if map.has-key($colors, $color ) {
    @return map.get( map.get($colors, $color ), $hue );
  }

  @warn $colors;
  @error 'Map `$colors` does not have the `#{$color}` key.';
}


@function _size($v) {
  $base: 16;  // default HTML font size is 16px;

  @return math.div($v, $base);
}

/**
SCSS
  .sample { font-size: rem(20);   margin: em(32) 0; }

CSS output
  .sample { font-size: 1.25rem;   margin: 2em 0; }
 */
@function rem($pxValue) { @return _size($pxValue) * 1rem; }   // must * 1<unit>, because + <unit> makes it a string instead of number
@function em($pxValue)  { @return _size($pxValue) * 1em; }    // must * 1<unit>, because + <unit> makes it a string instead of number
