@use "mixins" as *;

@charset "UTF-8";

@include keyframes(dash) {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

@include keyframes(fadeIn) {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@include keyframes(rotate) {
  100% {
    @include transform(rotate(360deg));
  }
}

@include keyframes(slideLeft) {
  0% {
    @include transform(translateX(100%))
  }
  100% {
    @include transform(translateX(0))
  }
}

@include keyframes(slideRight) {
  0% {
    @include transform(translateX(0))
  }
  100% {
    @include transform(translateX(100%))
  }
}
