@use "../functions" as *;

@mixin flow-theme-chips($theme) {
  .mat-mdc-standard-chip {
    height: 24px !important;

    &:not(.mdc-evolution-chip--disabled) {
      background-color: fcolor(white, 80) !important;
    }

    .mdc-evolution-chip__text-label {
      font-size: rem(12)  !important;
      font-weight: 500  !important;
      color: var(--black) !important;
    }
  }
}
