@use "../breakpoints" as *;
@use "../functions" as *;
@use "../mixins" as *;
@use "../variables" as *;

@mixin toolbar-tab {
  background-color: transparent;
  border-color: var(--tab-border-color);
  color: var(--tab-color);
  opacity: 1;
}

@mixin flow-theme-tabs($theme) {
  .mat-mdc-tab-group,
  .mat-mdc-tab-nav-bar {
    --mdc-tab-indicator-active-indicator-color: var(--primary) !important;
    --mat-tab-header-active-label-text-color: var(--primary) !important;
    --mat-tab-header-active-ripple-color: transparent !important;
    --mat-tab-header-inactive-ripple-color: var(--primary) !important;
    --mat-tab-header-active-focus-label-text-color: var(--primary) !important;
    --mat-tab-header-active-hover-label-text-color: var(--primary) !important;
    --mat-tab-header-active-focus-indicator-color: var(--primary) !important;
    --mat-tab-header-active-hover-indicator-color: var(--primary) !important;
  }

  .mat-mdc-tab-nav-bar,
  .mat-mdc-tab-header {
    border-bottom: $border-bottom-on-light;
    margin-bottom: 20px;
  }

  .mat-mdc-tab {
    min-width: 160px !important;
    letter-spacing: normal !important;

    .mdc-tab__text-label {
      color: var(--text-color);
      font-size: rem(12);
      text-transform: uppercase;
    }
  }

  .mat-mdc-tab-body-content {
    padding: 1px;
  }

  .tabs-small-label-width {
    .mat-mdc-tab {
      min-width: 100px;
    }
  }

  .tabs-full-height {
    height: 100%;
    max-height: 100%;

    .mat-mdc-tab-body-content {
      overflow: hidden;
    }

    .mat-mdc-tab-body-wrapper {
      height: 100%;

      .mat-mdc-tab-body.mat-mdc-tab-body-active {
        max-height: 100%;
        height: 100%;
      }
    }
  }

  .tabs {
    position: relative;
    margin: 0;
    padding: 0;
    min-width: 50px;

    @include dflex(0, 0, 50px);

    &.tabs-nav-overflow {
      @include dflex(1, 0, auto);
    }

    &.tabs-nav-wrapper {
      width: 100%;
      overflow: hidden;
      padding: 0 2px;
      @include dflex(1, 1, auto);
    }

    .tab {
      margin: 0 2px;
      min-width: 0;
      width: auto;
      visibility: hidden;
      opacity: 0;
      color: var(--bookmark-color);
      display: inline-flex;
      align-items: center;

      @include flex-grow(0);
      @include flex-shrink(0);
      @include flex-basis(auto);
      @include align-items(center);
      @include transition();

      &.bookmark-visible {
        visibility: visible;
        opacity: 1;
      }

      height: 40px;
      max-height: 40px;
      line-height: 40px;
      padding: 0 16px;
      cursor: pointer;
      font-size: rem(13);
      background: var(--bookmark-bg-color);
      -webkit-border-top-left-radius: 8px;
      -webkit-border-top-right-radius: 8px;
      -moz-border-radius-topleft: 8px;
      -moz-border-radius-topright: 86px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;

      border-style: solid;
      border-width: 1px;
      border-color: var(--bookmark-bg-color);
      border-bottom-width: 0;

      span {
        margin-left: 10px;
      }

      .material-icons {
        font-size: rem(18);
        margin-top: -2px;
      }

      .flow-icon {
        font-size: rem(18);
      }

      &.current-menu-item,
      &:hover {
        opacity: 1;
        background: var(--nav-bg-color);
        border-color: var(--nav-bg-color);
      }

      &.current-menu-item {
        color: var(--bookmark-color-active);
      }

      &:hover {
        color: var(--bookmark-color-hover);
      }

      &:active,
      &:focus {
        outline: none;
      }

      &.tab-hide-icon {
        @include media-breakpoint-down(md) {
          span {
            display: none;
          }

          &.current-menu-item,
          &:hover {
            span {
              display: inline-block;
            }
          }
        }
      }
    }

    &.tabs-toolbar {
      .tab {
        @include toolbar-tab;
        text-align: center;
        visibility: visible;

        &:hover,
        &.current-menu-item {
          border-color: var(--tab-bg-color-active);
          background-color: var(--tab-bg-color-active);
          color: var(--tab-color-active);
        }

        &:hover {
          opacity: .8;
        }

        &.disabled {
          cursor: not-allowed;

          &:hover {
            @include toolbar-tab;
          }
        }
      }
    }
  }
}
