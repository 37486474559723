@use "@angular/material" as mat;

// mixin for material
@use "functions" as *;
@use "breakpoints" as *;
@use "mixins" as *;

// Components or theme dependant elements.
@use "elements/alerts" as *;
@use "elements/body" as *;
@use "elements/badges" as *;
@use "elements/buttons" as *;
@use "elements/button-toggle" as *;
@use "elements/toolbars" as *;
@use "elements/tabs" as *;
@use "elements/lists" as *;
@use "elements/checkboxes" as *;
@use "elements/modals" as *;
@use "elements/menus" as *;
@use "elements/accordions" as *;
@use "elements/forms" as *;
@use "elements/radios" as *;
@use "elements/recaptcha" as *;
@use "elements/switches" as *;
@use "elements/timepicker" as *;
@use "elements/tooltips" as *;
@use "elements/selects" as *;
@use "elements/snackbar" as *;
@use "elements/tiles" as *;
@use "elements/chips" as *;
@use "elements/errors" as *;
@use "elements/paginator" as *;


/**
 * Flow theme generator
 *
 * This mixin is copied from the material mixin.
 * We only use certain components from the framework.
 */
@mixin flow-theme($theme) {
  @include mat.core-theme($theme);
  @include mat.autocomplete-theme($theme);
  @include mat.button-toggle-theme($theme);
  @include mat.card-theme($theme);
  @include mat.chips-theme($theme);
  @include mat.checkbox-theme($theme);
  @include mat.datepicker-theme($theme);
  @include mat.dialog-theme($theme);
  @include mat.expansion-theme($theme);
  @include mat.form-field-theme($theme);
  @include mat.input-theme($theme);
  @include mat.menu-theme($theme);
  @include mat.paginator-theme($theme);
  // @include mat.progress-bar-theme($theme);
  // @include mat.progress-spinner-theme($theme);
  @include mat.radio-theme($theme);
  @include mat.select-theme($theme);
  @include mat.slide-toggle-theme($theme);
  @include mat.snack-bar-theme($theme);
  @include mat.stepper-theme($theme);
  @include mat.tabs-theme($theme);
  @include mat.tooltip-theme($theme);
  // @include mat-sidenav-theme($theme);

  // @flow mixins
  @include flow-theme-buttons($theme);
  @include flow-theme-button-toggle($theme);
  // @include flow-theme-badges($theme);
  // @include flow-theme-toolbars($theme);
  @include flow-theme-forms($theme);
  @include flow-theme-tabs($theme);
  // @include flow-theme-inputs($theme);
  @include flow-theme-lists($theme);
  @include flow-theme-checkboxes($theme);
  @include flow-theme-modals($theme);
  @include flow-theme-menus($theme);
  @include flow-theme-accordions($theme);
  @include flow-theme-radios($theme);
  @include flow-theme-switches($theme);
  @include flow-theme-tooltips($theme);
  @include flow-theme-selects($theme);
  @include flow-theme-snackbar($theme);
  @include flow-theme-tiles($theme);
  @include flow-theme-chips($theme);
  @include flow-theme-paginator($theme);
}
