@use "../functions" as *;
@use "../mixins" as *;

@mixin flow-theme-forms($theme) {
  html {
    --mat-form-field-container-height: 51px;
    --mat-form-field-container-text-line-height: 16.2px;
    --mat-form-field-container-text-size: 14.4px;
    --mat-form-field-container-vertical-padding: 8px;
    --mat-form-field-focus-select-arrow-color: var(--primary);
    --mat-form-field-state-layer-color: transparent;
    --mat-form-field-error-text-color: var(--danger);

    --mat-form-field-outlined-label-text-populated-size: 14.4px;
    --mdc-outlined-text-field-label-text-size: 14.4px;
    --mdc-outlined-text-field-container-shape: 0px;
    --mdc-outlined-text-field-caret-color: var(--primary);
    --mdc-outlined-text-field-focus-outline-color: var(--primary);
    --mdc-outlined-text-field-focus-label-text-color: var(--primary);
    --mdc-outlined-text-field-label-text-color: var(--text);
    --mdc-outlined-text-field-hover-label-text-color: var(--text);
    --mdc-outlined-text-field-input-text-color: var(--black);
    --mdc-outlined-text-field-input-text-placeholder-color: var(--text);
    --mdc-outlined-text-field-disabled-input-text-color: rgb(0, 0, 0, 0.38);
    --mdc-outlined-text-field-error-caret-color: var(--danger);
    --mdc-outlined-text-field-error-focus-label-text-color: var(--danger);
    --mdc-outlined-text-field-error-label-text-color: var(--danger);
    --mdc-outlined-text-field-error-hover-label-text-color: var(--danger);
    --mdc-outlined-text-field-outline-color: #ddd;
    --mdc-outlined-text-field-hover-outline-color: #ddd;
    --mdc-outlined-text-field-error-focus-outline-color: var(--danger);
    --mdc-outlined-text-field-error-hover-outline-color: var(--danger);
    --mdc-outlined-text-field-error-outline-color: var(--danger);

    --mdc-filled-text-field-label-text-size: 14.4px;
    --mdc-filled-text-field-caret-color: var(--primary);
    --mdc-filled-text-field-focus-active-indicator-color: var(--primary);
    --mdc-filled-text-field-focus-label-text-color: var(--primary);
    --mdc-filled-text-field-label-text-color: var(--text);
    --mdc-filled-text-field-hover-label-text-color: var(--text);
    --mdc-filled-text-field-input-text-color: var(--black);
    --mdc-filled-text-field-input-text-placeholder-color: var(--text);
    --mdc-filled-text-field-error-hover-label-text-color: var(--danger);
    --mdc-filled-text-field-error-focus-label-text-color: var(--danger);
    --mdc-filled-text-field-error-label-text-color: var(--danger);
    --mdc-filled-text-field-error-caret-color: var(--danger);
    --mdc-filled-text-field-active-indicator-color: var(--primary);
    --mdc-filled-text-field-hover-active-indicator-color: var(--primary);
    --mdc-filled-text-field-error-active-indicator-color: var(--danger);
    --mdc-filled-text-field-error-focus-active-indicator-color: var(--danger);
    --mdc-filled-text-field-error-hover-active-indicator-color: var(--danger);
  }

  .mdc-text-field,
  .mat-mdc-form-field-hint-wrapper,
  .mat-mdc-form-field-error-wrapper {
    padding: 0 8px !important;
  }

  .mdc-text-field--outlined {
    .mdc-notched-outline {
      .mdc-notched-outline__leading {
        border-top: none;
        border-left: none;
        width: 4px !important;
      }

      .mdc-notched-outline__notch {
        border-top: none;
      }

      .mdc-notched-outline__trailing {
        border-top: none;
        border-right: none;
      }
    }

    .mat-mdc-form-field-infix {
      min-width: min-content !important;
      padding-top: 22px !important;
    }

    .mdc-notched-outline--no-label + .mat-mdc-form-field-infix  {
      padding-top: 8px !important;
    }
  }

  .mat-mdc-form-field-infix {
    min-height: 0 !important;
  }

  .mat-mdc-text-field-wrapper {
    &.mdc-text-field--outlined {
      .mdc-notched-outline--upgraded {
        .mdc-floating-label--float-above {
          // Original from angular material:
          // --mat-mdc-form-field-label-transform: translateY(calc(calc(6.75px + var(--mat-form-field-container-height) / 2) * -1)) scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
          // transform: var(--mat-mdc-form-field-label-transform);
          transform: matrix(0.75, 0, 0, 0.75, 0, -22.75) !important;
        }
      }
    }
  }

  .mdc-floating-label {
    overflow: visible !important;
  }

  // Asterisk
  .mdc-floating-label--required {
    &:not(.mdc-floating-label--hide-required-marker)::after {
      color: var(--danger);
      margin-left: 3px !important;
    }
  }

  .mat-mdc-form-field-textarea-control {
    overflow: unset;
  }

  .mat-mdc-form-field-icon-suffix {
    >.mat-icon {
      color: var(--primary) !important;
      font-size: 1rem !important;
      padding: 0 !important;
    }
  }

  .mat-mdc-form-field-hint {
    color: var(--info-text-color);
    @include flexbox();
    @include align-items();
    font-size: 0.75rem;
    line-height: 1rem;
    margin-top: 10px;
  }

  .form-group-label {
    color: var(--black);
    display: flex;
    align-items: center;

    .material-icons {
      vertical-align: middle;
      font-size: rem(14);
      line-height: normal;
      margin-right: 5px;
      margin-top: -2px;
      cursor: pointer;
      @include transition(color);
      &:hover {
        color: var(--primary);
      }
    }

    span.lang-label {
      background-color: fcolor(gray, 20);
      border-radius: 3px;
      font-size: .75rem;
      margin-left: .5rem;
      padding: 2px 4px;
    }

    span.title {
      font-weight: 500;
    }

    &.with-info {
      align-items: flex-start;
      flex-direction: column;

      span.info {
        display: block;
        clear: both;
        color: var(--info-text-color);
        font-size: .75rem;
        margin-top: 2px;
      }
    }

    &.required::after {
      color: var(--danger);
      content: '*';
      margin-left: 3px;
      position: relative;
      top: -2px;
    }

    &.fw-400 {
      font-weight: 400;
    }

    &.fw-500 {
      font-weight: 500;
    }
  }

  .mat-error {
    color: var(--danger);
  }

  .custom-mat-form-error {
    color: var(--danger);
    font-size: 0.7rem;
    position: relative;
    top: -12px;
  }

  .repeated_collapsible__actions,
  .repeated__actions {
    margin-top: 9px;

    button {
      &.button-icon {
        .material-icons {
          vertical-align: middle;
          font-size: rem(22);
          padding: 0;

          &.remove {
            color: var(--danger);
          }

          &:hover {
            opacity: .65;
          }
        }
      }
    }
  }
}
