@use "functions" as *;
@use "variables" as *;

html {
  line-height: 1.4;
  -webkit-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  word-break: break-word;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 90%;
}

p {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--black);
  font-family: inherit;
  font-weight: 300;
  line-height: 1;
  margin: 0;
}

h1 {
  font-size: rem(42);
}

h2 {
  font-size: rem(30);
  font-weight: 400;
}

h3 {
  font-size: rem(24);
}

h4 {
  font-size: rem(18);
}

h5 {
  font-size: rem(16);
}

h6 {
  font-size: rem(14);
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
div,
section,
summary {
  display: block
}

ul,
ol {
  list-style: square;
  margin: 0;
  padding: 0;

  &.list-inline {
    list-style: none;
    padding: 0;
    > li {
      margin: 0 15px 0 0;
      display: inline-block;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

a {
  background-color: transparent;
  outline: none;
  text-decoration: none;
  &:hover {
    text-decoration: none
  }
}

small {
  font-size: 75%
}
figure {
  margin: 0 0 1em 0;
}

img {
  border: 0;
  border-style: none;
  display: block;
  max-width: 100%;
  height: auto;
  float: none;
  margin: 0 auto;
}

pre {
  white-space: normal;
  border: 1px dashed #eee;
  background: #eee;
  font-size: rem(12);
  padding: 10px;
  color: #666;
}

abbr {
  color: var(--danger);
  font-size: .9rem;
}

table.flow-table {

  width: 100%;
  max-width: 100%;
  border-collapse: collapse;

  th {
    font-weight: 500;
    color: rgba(0, 0, 0, 0.54);
  }

  th,
  td {
    font-size: rem(12);
    text-align: left;
    padding: 12px 13px;
    border-bottom: $border-bottom-on-light;

    &.actions {
      padding-left: 0;
      padding-right: 0;
      text-align: center;
    }
  }

  thead {
    tr {
      th {
        height: 50px;
        min-height: 50px;
        max-height: 50px;
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }

  th.actions {
    width: 50px;
    max-width: 50px;
  }

  tfoot {
    tr {
      td {
        padding: 0;
      }
    }
  }

  &.flow-table-hover {
    tr {
      &:hover {
        td:not(.actions) {
          cursor: pointer;
          opacity: .7;
        }
      }
    }
  }
}

[hidden] {
  display: none !important;
}

iframe {
  &.flow-iframe {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    border: 0;
    background: transparent;
  }
}
