@mixin flow-theme-tooltips($theme) {
  .mat-mdc-tooltip {
    &.tooltip-multi-line {
      white-space: pre-line;

      .mdc-tooltip__surface {
        max-width: max-content !important;
      }
    }

    .mdc-tooltip__surface {
      font-size: .75rem !important;
      font-weight: 300 !important;
    }
  }
}
