@use "breakpoints" as *;
@use "functions" as *;
@use "mixins" as *;

.truncate {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
}

.text-bold {
  font-weight: bold;
}

.text-bold-500 {
  font-weight: 500;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-underline {
  text-decoration: underline;
}

.flow-logo {
  background-image: url('../images/flow-partner-automation-logo.svg');
  @include background(no-repeat, contain, center left);
}

.content-wrapper {
  padding: 30px;
}

.fluid-wrapper {
  padding-left: 30px;
  padding-right: 30px;
}

@include media-breakpoint-down(sm) {
  .content-wrapper,
  .fluid-wrapper {
    padding-left: 15px;
    padding-right: 15px;
  }

  .hidden-mobile {
    display: none !important;
  }
  .col {
    margin-bottom: 15px;
  }
}

@include media-breakpoint-down(md) {
  .hidden-lower-md {
    display: none !important;
  }
}

.background-image-element {
  @include background(no-repeat, cover, center center );

  &.background-image-element-contain {
    &.background-image-loaded {
      @include backgroundSize(contain);
    }
  }

  &.background-image-element-fadeIn {
    &.background-image-loaded {
      @include animation(fadeIn, 1s );
    }
  }

  &.background-image-element-blur {
    filter: blur(500px);
    -webkit-filter: blur(500px);
    @include transition(filter, 800ms, ease);

    &.background-image-loaded {
      -webkit-filter: blur(0);
      filter: blur(0);
      filter: none;
    }
  }

}

.absolute-center {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.absolute-center-fixed {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.absolute-relative {
  position: relative;
  width: 100%;
  height: 100%;
  @include flexbox();
  align-items: center;
  justify-content: center;

  &.left {
    justify-content: flex-start;
  }
}

.position-relative {
  position: relative;
}

.mat-mdc-form-field {
  width: 100%;
}

.opacity-link {
  @include transition(opacity);
  &:hover {
    opacity: .8;
  }
}

@include flow-font-sizes();

.rotate-90 {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}

.full-height {
  height: 100%;

  &.full-height-flex {
    display: flex;
    overflow-y: auto;
    max-height: 100%;
    flex-direction: column;
  }
}

.br-3 {
  @include borderRadius(3px);
}

.br-half {
  @include borderRadius(50%);
}

.link {
  color: var(--primary);
  &:hover {
    text-decoration: underline;
  }
}

.icon-3x {
  font-size: rem(48);
}

.drag-indicator {
  cursor: move !important;
}

.ws-normal {
  white-space: normal;
}
