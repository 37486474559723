//
// This file contains special styles for the standard einvoicing project.
// The id sps-einvoicing is added to the body tag by setBodyId() in env.service.ts
//

@use "partials/breakpoints" as *;
@use "partials/mixins" as *;

@mixin sps-tab-transition {
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  -ms-transition: none;
  transition: none;
}

#sps-einvoicing {
  --flow-content-router-outlet-height: calc(100vh - 157px);
  --flow-content-router-outlet-height-md: calc(100vh - 93px);
  --flow-content-admin-wrapper-router-outlet-height: calc(100vh - 232px);
  --flow-content-admin-wrapper-router-outlet-height-md: calc(100vh - 168px);
  --sps-deep-blue: #0079a5;
  --sps-pale-blue: #d9f2fb;
  --sps-pale-grey: #f6f6f6;

  /* Generic */

  .flow-content-preload-container {
    height: var(--flow-content-router-outlet-height);

    @include media-breakpoint-up(md) {
      height: var(--flow-content-router-outlet-height-md);
    }
  }

  /* Header */

  #header {
    box-shadow: 0 1px 2px #00000026;
    justify-content: end;
    min-height: 3.75rem;
    z-index: 50;

    .sps-navbar {
      align-items: center;
      height: 3.625rem;

      .sps-navbar__brand {
        display: flex;
        font-size: .875rem;
        margin-right: 1.875rem;
        white-space: nowrap;
      }

      .sps-navbar__brand-name {
        color: #1f282c;
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.5rem;
        text-transform: uppercase;
      }

      .sps-navbar__brand-logo {
        height: 1.5rem;
        margin-right: .625rem;
        width: 1.5rem;
      }

      .sps-vertical-rule {
        background: #d2d4d4;
        display: inline-block;
        height: 2.125rem;
        margin: 0 .9375rem 0;
        vertical-align: middle;
        width: .0625rem;
      }

      .help-icon .button .material-icons {
        font-size: 1.625rem;
      }

      .material-icons {
        &.large {
          font-size: 1.625rem;
        }
      }

      .tabs {
        .tab {
          @include sps-tab-transition();

          border: none;
          border-bottom: .125rem solid transparent;
          font-weight: 400;
          height: 3.625rem;
          max-height: 3.625rem;
          line-height: 3.625rem;

          &.current-menu-item,
          &:hover {
            background: none;
          }

          &.current-menu-item {
            border-bottom: .125rem solid var(--bookmark-color-active);
          }

          &.tab-hide-icon {
            @include media-breakpoint-down(md) {
              span {
                display: inline-block;
              }

              &.current-menu-item,
              &:hover {
                span {
                  display: inline-block;
                }
              }
            }
          }

          span {
            &.mobile-portrait-icon-only {
              display: none;
            }

            @media (min-width: 480px) {
              &.mobile-portrait-icon-only {
                display: inline-block;
              }

            }
          }

          .material-icons {
            &.mobile-portrait-icon-only {
              display: inline-block;
              margin-right: 10px;
            }

            @media (min-width: 480px) {
              &.mobile-portrait-icon-only {
                display: none;
              }

            }
          }
        }
      }
    }
  }

  /* Footer */

  #footer-actions {
    ul {
      justify-content: space-between;
      width: 100%;
    }
  }

  /* Menu */

  .menu-top {
    padding: 0;

    @include media-breakpoint-up(md) {
      padding: 0 0 0 1rem;
    }
  }

  /* Dashboard */

  gridster {
    gridster-item {
      box-shadow: none;
      border: .0625rem solid #d2d4d4;
      border-radius: .1875rem;

      &.headless-widget {
        background: var(--sps-pale-grey);
      }

      &.transparent-widget {
        background: transparent;
        border: none;
      }

      &:hover {
        border-color: var(--sps-deep-blue);
      }
    }
  }

  /* DM coomponents */

  #dm-documents-wrapper {
    height: var(--flow-content-router-outlet-height);

    @include media-breakpoint-up(md) {
      height: var(--flow-content-router-outlet-height-md);
    }
  }
}
