@use "../functions" as *;
@use "../mixins" as *;

@mixin flow-theme-selects($theme) {
  html {
    --mat-select-trigger-text-size: 14.4px;
    --mat-select-enabled-arrow-color: var(--black);
    --mat-select-disabled-trigger-text-color: rgb(0, 0, 0, 0.38);
    --mat-option-label-text-line-height: 21px;
    --mat-option-label-text-size: 14px;
    --mat-option-label-text-color: var(--black);
    --mat-option-selected-state-label-text-color: var(--primary);
  }

  .mat-primary {
    --mat-full-pseudo-checkbox-selected-icon-color: var(--primary);
    --mat-minimal-pseudo-checkbox-selected-checkmark-color: var(--primary);
  }

  .mat-mdc-form-field-type-mat-select {
    &.mat-form-field-appearance-outline {
      .mat-mdc-form-field-infix {
        padding-bottom: 4px !important;   // Reduce distance form mat-select option to bottom
        padding-top: 18px !important;     // Reduce distance form mat-select option to top
      }
    }
  }

  .mat-mdc-select-arrow {
    margin-left: 10px;
  }

  .mat-mdc-select-multiple {
    .mat-mdc-select-value-text {
      white-space: normal;
    }
  }

  .mat-mdc-select-panel {
    .mat-mdc-optgroup-label {
      .mdc-list-item__primary-text {
        width: -webkit-fill-available;
        width: stretch;
        width: -moz-available;
      }
    }

    &.flow-products-dropdown {
      .mat-mdc-option {
        @include flexbox();
        @include align-items();

        .material-icons {
          line-height: normal;
          vertical-align: middle;
          font-size: rem(16);
        }

        .material-icons,
        .flow-icon {
          margin-right: 8px;
          color: var(--primary);
        }
      }
    }
  }
}
