.flow-login {
  &.recaptcha-container {
    position: relative;
    height: 100px;
    margin: 0 -49px 0;

    .recaptcha {
      position: absolute;
      transform: scale(.7);
    }
  }

  @media (min-width: 320px) {
    &.recaptcha-container {
      position: relative;
      height: 100px;
      margin: 10px -33px 0;

      .recaptcha {
        position: absolute;
        transform: scale(.8);
      }
    }
  }

  @media (min-width: 360px) {
    &.recaptcha-container {
      position: relative;
      height: 100px;
      margin: 10px -17px 0;

      .recaptcha {
        position: absolute;
        transform: scale(.9);
      }
    }
  }
}
