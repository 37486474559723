@mixin flow-theme-switches($theme) {
  .mat-mdc-slide-toggle {
    &.mat-accent {
      --mdc-switch-selected-focus-state-layer-color: var(--primary) !important;
      --mdc-switch-selected-handle-color: var(--primary) !important;
      --mdc-switch-selected-hover-state-layer-color: var(--primary) !important;
      --mdc-switch-selected-pressed-state-layer-color: var(--primary) !important;
      --mdc-switch-selected-focus-handle-color: var(--primary) !important;
      --mdc-switch-selected-hover-handle-color: var(--primary) !important;
      --mdc-switch-selected-pressed-handle-color: var(--primary) !important;
      --mdc-switch-selected-focus-track-color: var(--primary-bright) !important;
      --mdc-switch-selected-hover-track-color: var(--primary-bright) !important;
      --mdc-switch-selected-pressed-track-color: var(--primary-bright) !important;
      --mdc-switch-selected-track-color: var(--primary-bright) !important;
    }

    .mdc-switch {
      margin-right: 6px !important;
    }
  }
}
