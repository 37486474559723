@use "../functions" as *;

.error {
  background: rgba( fcolor(danger, 20), .4);
  border: 1px solid var(--danger);
  padding: 1rem;

  &.msg {
    color: var(--danger);
  }
}