:root {
  --white: #fefefe;
  --black: #111;
  --primary: fcolor(blue);
  --primary-i-30: fcolor(blue, 30);
  --gray: fcolor(gray);
  --danger: #cf000f;
  --success: fcolor(success, 100);
  --background: #fefefe;
  --text: #868686;
}
