/* You can add global styles to this file, and also import other style files */
@use "flow-theme-marketplace" as *;
@use "flow-styles" as *;
@use "flow-essentials" as *;
@use "sps-einvoicing" as *;

a {
  cursor: pointer;
}

.browserupgrade {
  color: red;
  text-align: center;
  padding: 0.625rem 0;
  z-index: 999;
  position: relative;
  background: #fff;

  // in case both .browserupgrade are in the html, then hide the 2nd one
  + .browserupgrade {
    display: none;
  }
}

/* hide the .browserupgrade msg on:
    Chrome: 28+
    Safari: 9+
    Internet Explorer/Edge: 12+
    Opera: 14+
    Firefox: ?? (works, tested on (mac) version 78)
 */
@supports (-webkit-appearance:none) {
  .browserupgrade {
    display: none;
  }
}

/*
main {
  // background-color: var(--content-bg-color);
  // min-height: 100vh; // Needs to be added again if we want to have a colorized background
}
*/

.flow-content-preload-container {
  align-items: center;
  display: flex;
  height: var(--flow-content-router-outlet-height);
  justify-content: center;
  overflow: hidden;
}

.setting-rows-list {
  // max-width: 767px;
  &.settings-row-list-border {
    .setting {
      border-top: 2px dotted #eee;

      &:first-child {
        border-top: 0;
      }
    }

  }

  &.settings-row-list-labels-block {
    .form-group-label {
      flex-direction: column;
      align-items: flex-start;

      span {
        display: block;
        clear: both;
        color: var(--info-text-color);
      }
    }
  }

  &.settings-row-list-labels-inline {
    .form-group-label {
      flex-direction: row;
      align-items: center;
    }
  }
}

.search-form-wrapper {
  position: relative;
  background: #efefef;
  width: 100%;
  border-bottom: 1px solid #ddd;
  padding: 1rem 1rem 0 1rem;
}

.checklist {
  color: var(--black);
  list-style: none;

  ul {
    list-style: none;
  }

  li {
    line-height: 1.5;
    margin-bottom: 8px;
    &:before {
      font-family: 'Material Icons';
      content: '\e5ca';
      color: fcolor(blue, 20);
      margin-right: 5px;
      font-size: rem(14);
    }
  }
}

.content-title {
  position: sticky;
  top: 0;
  z-index: 33;
  background: var(--nav-bg-color);
  height: 75px;
  max-height: 75px;

  h2 {
    color: var(--content-title-color);
    font-size: rem(22);
  }

  @include media-breakpoint-down(md) {
    position: relative;
  }
}

.list-item {
  position: relative;
  padding-top: .75rem;
  padding-bottom: .75rem;
  border: 1px solid #efefef;
  cursor: pointer;
  @include boxShadow(none);
  @include transition(box-shadow);

  h6 {
    font-weight: 400;
    color: var(--text);
    margin-bottom: 3px;
  }

  p {
    margin: 0;
    font-size: rem(12);
  }

  .material-icons {
    font-size: rem(18);
  }

  .list-item-hover {
    background: rgba(255, 255, 255, .83);
    visibility: hidden;
    opacity: 0;
    @include transition(all, 100);
  }

  &:hover {
    @include boxShadow(0 4px 4px 0 rgba(0,0,0,0.04), 0 1px 16px 0 rgba(0,0,0,0.16), 0 2px 4px 0 rgba(0,0,0,0.08));
    @include transition(all);
    .list-item-hover {
      opacity: 1;
      visibility: visible;
    }
  }

}

/* Dashboard */

gridster {
  width: 100% !important;
  max-width: 100% !important;

  gridster-item {
    @include boxShadow(0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12));
    background: var(--grid-bg-color);

    &.headless-widget {
      .drag-handler {
        top: 0;
      }

      .remove-widget {
        top: 6px;
      }
    }

    .gridster-item-resizable-handler {
      z-index: 9999 !important;
    }

    .drag-handler {
      cursor: pointer;
      background: rgba(255, 255, 255, .95);
      z-index: 30;
      top: 50px;
    }

    .remove-widget {
      position: absolute;
      top: -44px;
      right: 10px;
      z-index: 40;
    }

    .drag-handler,
    .remove-widget {
      .button-icon {
        .material-icons {
          font-size: rem(24);
          color: var(--primary);
        }
      }
    }
  }

  .gridster-column {
    border-left: 1px solid #ccc !important;
    border-right: 1px solid #ccc !important;
  }

  .gridster-row {
    border-top: 1px solid #ccc !important;
    border-bottom: 1px solid #ccc !important;
  }
}

.flow-dashboard-widget {
  &:hover .button-icon {
    display: block;
  }

  .mat-mdc-paginator-container {
    min-height: auto;
  }

  .mat-mdc-paginator-icon {
    width: 20px;
  }

  .mat-mdc-icon-button {
    width: 24px;
    height: 24px;
    line-height: 24px;
    padding: 2px 0;
  }
}

.flow-widget-title {
  a {
    color: var(--primary);
    font-weight: 700;

    &:hover {
      text-decoration: underline;
    }
  }
}

.flow-widget-content {
  position: absolute;
  top: 50px;
  right: 0;
  left: 0;
  bottom: 0;
  color: var(--chart-label-color);
  display: flex;
  align-items: center;
  justify-content: center;

  &.scrollable {
    align-items: unset;
    overflow-x: hidden;
    overflow-y: auto;

    &.bg-inverse {
      background: #f5f5f5; // maybe make this themable
    }
  }

  .ngx-charts {
    text {
      fill: var(--chart-label-color) !important;
    }
  }

  .gridline-path {
    stroke: var(--chart-grid-line-color) !important;
  }

  .tick {
    text {
      font-size: rem(10) !important;
    }
  }

  .x.axis {
    .tick {
      text {
      }
    }
  }
}

.flow-headless-widget-content {
  height: 100%;
  position: absolute;
  width: 100%;
  box-sizing: border-box;
}

.flow-dm-product-catalogue-widget-wrapper {
  .mat-mdc-paginator {
    position: relative;
    top: 4px;

    .mat-mdc-paginator-container {
      min-height: auto;

      .mat-mdc-paginator-range-label {
        left: 4px;
        margin: 0px;
        position: absolute;
      }
    }
  }
}

.tile {
  .tile-links {
    margin-top: 1rem;
    list-style: none;
    li {
      margin-bottom: 1rem;
      text-align: left;

      a {
        padding: .5rem;
        display: block;
        span {
          font-size: rem(13);
          color: var(--primary);
        }
      }

      small {
        display: block;
        clear: both;
        color: var(--text);
        font-size: rem(11);
      }

      &:hover {
        a {
          background: rgba(33, 33, 33, .022);
        }
      }
    }
  }
}

.page-navigation {
  ul {
    //padding: 2rem 0;
    list-style: none;
    margin-right: 45px;
    height: 100%;
    /* border-right: 3px solid #eee; // former scheme */
    border-right: 3px solid #ebebeb;

    li {
      a {
        cursor: pointer;
        position: relative;
        /* font-size: 12px; // former scheme */
        font-size: 0.85rem;
        display: block;
        /* text-transform: uppercase; // former scheme */
        padding: 1rem .5rem;
        font-weight: 400;
        color: var(--page-nav-color);
        &:after {
          top: 0;
          position: absolute;
          width: 3px;
          right: -3px;
          background: transparent;
          height: 100%;
          content: " ";
          @include transition(background-color);
        }
      }

      &.active {
        a {
          color: var(--page-nav-color-active);
          background: var(--page--nav-bg-color);
          font-weight: 500;

          &:after {
            background-color: var(--primary);
          }
        }
      }

      @mixin mx-highlight-state() {
        background: rgba(30, 30, 30, .01);

        &:after {
          opacity: .7;
          /* background-color: var(--primary-i-30); // former scheme */
          background-color: var(--primary-hover);
        }
      }

      &:not(.active) {

        &:hover {
          a {
            @include mx-highlight-state();
          }
        }

        a:focus {
          @include mx-highlight-state();

          font-weight: 500;
        }
      }
    }
  }
}

.cdk-drag-preview {
  background: var(--white);
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
  0 8px 10px 1px rgba(0, 0, 0, 0.14),
  0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.dnd-placeholder {
  background: #eee;
  border: dotted 1px #eee;
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  min-height: 56px;
}

.items-list {
  color: var(--black);

  > li {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: .5rem 0;
    border-bottom: 1px solid #eee;

    &:last-child {
      border-bottom: 0;
    }
  }

  &.dnd-list {
    > li {
      padding: .8rem 0 0 0;
    }
  }
}

.data-text-container {
  ul {
    margin-left: 1em;
    li {
      margin-bottom: 2px;
    }
  }
}

.howto-step {
  color: darken(fcolor(gray), 20%);
  display: inline-flex;
  align-items: center;

  > span {
    display: inline-block;
    min-width: 20px;
    padding: 3px 5px;
    @include borderRadius(3px);
    background: var(--primary);
    color: var(--white);
    margin-right: 10px;
    text-align: center;
  }
}

iframe {
  &#launcher {
    position: absolute !important;
    //  display: none !important;
  }

  &.solution {
    width: 100%;
    height: 100%;
    position: relative;
    display: block;
    border: 0;
  }
}

.userform__pw-requirements {
  div:first-of-type {
    margin-left: -18px;
    margin-bottom: 10px;
  }
}


/* CMS */

.cms__category-info {
  .name {
    font-size: 1rem;
    margin-bottom: 3px;
  }

  .id {
    opacity: 0.6;
    font-size: 90%;

    > span {
      float: left;
      user-select: text; // allows selecting text in cdk draggable item
    }
  }
}

.cms__editor-wrapper {
  &.title {
    .ql-editor {
      min-height: 100px;
    }
  }

  .ql-editor {
    min-height: 200px;

    img {
      display: inline-block;
    }
  }
}

/* Userlane */

// Change position and size of userlane avatar
#userlane-assistant-container {
  transform: scale(.5);
  transform-origin: -50% 200%;

  #usln-p-assistant-avatar-new-tour {
    transform: scale(2);
  }

  [data-usln="player-assistant-navbar"][role=tablist] {
    transform: scale(2);
    transform-origin: 0 200%;

    ~ #usln-p-assistant-bubble {
      transform: scale(2);
      transform-origin: 0 100%;
      margin-bottom: 200%;
    }
  }

  [data-usln="player-announcements-carousel-wrapper"] {
    transform: scale(2);
    transform-origin: 0% 124%;
  }
}

// Previous styling changes seems to have no effect anymore.

/**
 * The '.userlane-assistant-branding' selector could be omitted,
 * is just to avoid showing technology by Userlane message.
*/
/* .userlane-assistant-branding,
.userlane-assistant-launcher {
  display: none !important;
}

.usln-p-assistant--position-bottom,
.usln-p-assistant--position-bottom > .usln-p-assistant__page-container {
  top: auto !important;
  bottom: -32px !important;
}

.usln-p-assistant--position-left {
  left: 4px !important;
}

.usln-p-assistant--position-left .usln-p-assistant-bubble,
.usln-p-assistant--position-left .usln-p-assistant__page-container {
  left: 20px !important;
}

// start fix: After adding the navbar by userlanes
.usln-p-assistant--position-bottom .usln-p-assistant-bubble,
.usln-p-assistant--position-bottom .usln-p-assistant__page-container,
.usln-p-assistant--position-bottom>.usln-p-assistant__page-container .usln-p-assistant-bubble,
.usln-p-assistant--position-bottom>.usln-p-assistant__page-container .usln-p-assistant__page-container {
  bottom: 154px !important;
}

.usln-p-assistant--position-left .usln-p-assistant-nav-bar {
  bottom: 80px !important;
  left: 20px !important;
}

.usln-p-assistant--position-left .usln-p-assistant-nav-bar .usln-p-assistant-avatar {
  bottom: -81px !important;
  left: -20px !important;
}
// end fix: After adding the navbar by userlanes

.usln-p-assistant-avatar__container {
  height: 24px !important;
  width: 24px !important;

  .usln-p-assistant-avatar__image {
    height: 24px !important;
    width: 24px !important;
  }

  .usln-p-assistant-avatar__notification {
    right: -10px !important;
    top: -5px !important;
  }

  .usln-p-assistant-avatar__notification {
    height: 18px !important;
    width: 18px !important;
    line-height: 16px !important;
    border: 1px solid #fff !important;
  }
} */

.help-icon {
  .button {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: .9em;
    padding: .4rem;
    text-transform: none;
    @include borderRadius(0);

    .material-icons {
      vertical-align: middle;
      font-size: rem(32);
      padding: 0;
    }
  }
}

.button {
  &.icon {
    &.loading {
      cursor: wait;
      opacity: .3;
    }
  }
}

.single-page {
  padding: 20px 20px 5px;

  .background {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: url('/assets/images/gateway-bg.svg') no-repeat center;
    background-size: cover;
    z-index: 0;
  }

  .flow-logo {
    width: 300px;
    height: 50px;
    margin: 0 auto 30px auto;
    display: block;
    clear: both;
  }

  .panel {
    border: 1px solid #e3e3e3;
    position: relative;
    min-height: 250px;
    box-sizing: border-box;
    margin: 0;
    background-color: #fff;
    z-index: 1;

    @include media-breakpoint-up(md) {
      margin: 30px auto 0 auto;
      max-width: 660px;
    }
  }

  .enforce-tfa {
    .mat-mdc-form-field {
      &-underline {
        position: static;
      }
      &-subscript-wrapper {
        position: static;
      }
    }

    .mat-mdc-form-field-hint {
      font-weight: 300;
      line-height: 0.9rem;
    }
  }
}

.mat-mdc-form-field-error.custom {
  font-size: 75%;
  font-weight: 400;
  margin-top: -15px;
  animation: custom-mat-error-animation 300ms cubic-bezier(0.55, 0, 0.55, 0.2);
}

@keyframes custom-mat-error-animation {
  0% {
    @include transform(translateY(-5px));
    opacity: 0;
  }
  100% {
    @include transform(translateY(0));
    opacity: 1;
  }
}

// hide iframes generated by sub-apps-logout.service.ts to logout sub-apps
IFRAME[sub-apps-logout-iframe] {
  position: absolute;
  right: 100vw;
  bottom: 100vh;
  visibility: hidden;
  z-index: -1;
}

//
// Angular Material
//

.mat-mdc-menu-panel {
  min-height: auto !important;

  &:not(.multi-level, .bookmarks-menu) {
    //max-width: min-content !important;
  }

  .logoutLink {
    border-top: 1px solid #eee;
  }

  &.topMenuDropdown {
    min-width: 230px;
    max-width: 230px;
    @include boxShadow(0px 3px 3px 0px rgba(0, 0, 0, 0.29));

    .mat-mdc-menu-content {
      padding: 0;
    }

    .mat-mdc-menu-item.indent {
      padding-left: 20px !important;
    }
  }

  &.notifications-menu {
    min-width: 320px;
    max-width: 320px;
    min-height: 0;
    max-height: 430px;
    font-size: .85rem;

    .mat-mdc-menu-content {
      padding: 0;
    }
  }

  &.bookmarks-menu {
    min-height: auto;
  }

  &:not(.topMenuDropdown, .bookmarks-menu) {
    button.mat-mdc-menu-item:not(:hover) {
      background: transparent;
    }
  }
}

.mat-mdc-menu-content {
  &:not(:empty) {
  padding-top: 0px !important;
  }
}

//
// DM components
//

#dm-documents-wrapper {
  display: flex;
  height: var(--flow-dm-documents-wrapper-height);

  .flow-portal-wrapper {
    .data-list[dm-datagrid] {
      border: none;
    }

    .error {
      height: fit-content;
      margin: 1.5rem;
      width: 100%;
    }

    .waiting-authorization {
      font-size: 1rem;
      left: 50%;
      position: absolute;
      text-align: center;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

#dm-document-filters-wrapper {
  --dm-gap: 15px;
  --dm-icon-height: 35px;
  --dm-icon-width: 35px;
  --dm-tile-count-font-size: 3.5rem;
  --dm-tile-name-font-size: 1.25rem;
  --dm-tile-footer-font-size: 0.75rem;

  .flow-portal-wrapper {
    .filter-tile[dm-pinned-filter] {
      border-radius: .1875rem;
      font-weight: 400;
      height: 285px;
      padding: 2.75rem 1.5rem 0;
      width: 301.25px;
    }

    .filter-count[dm-pinned-filter] {
      align-self: start;
      font-weight: 400;
    }

    .filter-footer[dm-pinned-filter] {
      align-items: center;
      display: flex;
      height: 50px;
      justify-content: center;
      letter-spacing: 1px;
      text-transform: uppercase;
    }

    .error {
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    .waiting-authorization {
      left: 50%;
      position: absolute;
      text-align: center;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
    }
  }
}
