@mixin flow-theme-checkboxes($theme) {
  html {
    --mdc-checkbox-selected-focus-icon-color: var(--primary);
    --mdc-checkbox-selected-hover-icon-color: var(--primary);
    --mdc-checkbox-selected-icon-color: var(--primary);
    --mdc-checkbox-selected-pressed-icon-color: var(--primary);
    --mdc-checkbox-selected-focus-state-layer-color: transparent;
    --mdc-checkbox-selected-hover-state-layer-color: transparent;
    --mdc-checkbox-selected-pressed-state-layer-color: transparent;
    --mdc-checkbox-unselected-icon-color: var(--text);
    --mdc-checkbox-unselected-hover-icon-color: var(--primary);
    --mdc-checkbox-unselected-focus-state-layer-color: transparent;
    --mdc-checkbox-unselected-hover-state-layer-color: transparent;
    --mdc-checkbox-unselected-pressed-state-layer-color: transparent;
    --mdc-checkbox-unselected-focus-icon-color: var(--text);
  }

  .mat-mdc-checkbox {
    --mdc-form-field-label-text-color: var(--text);

    margin-left: -11px;

    &.mat-accent {
      --mat-full-pseudo-checkbox-selected-icon-color: var(--primary);
      --mat-minimal-pseudo-checkbox-selected-checkmark-color: var(--primary);
    }

    .mdc-label {
      margin-top: 2px;
    }
  }

  .mdc-checkbox {
    position: unset !important;
  }

  .ng-invalid:not(.ng-untouched) .mat-mdc-checkbox.ng-invalid {
    &.mat-accent {
      --mdc-checkbox-unselected-focus-icon-color: var(--danger);
      --mdc-checkbox-unselected-icon-color: var(--danger);
    }

    .mdc-label {
      color: var(--danger);
    }
  }

  #modal-terms.ng-invalid:not(.ng-untouched) .mat-mdc-checkbox.ng-invalid {
    .mdc-label {
      color: var(--text) !important;
    }
  }
}
