@use "sass:map";

$navbars_height: 80px;
$sidenavs_width: 220px;

// Grid breakpoints.
$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px
) !default;

// Grid containers.
$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px
) !default;

$spacer: 1rem !default;
$spacers: (
        0: 0,
        1: ($spacer * .25),
        2: ($spacer * .5),
        3: $spacer,
        4: ($spacer * 1.5),
        5: ($spacer * 3)
);

$text-aligns: ('left', 'center', 'right');
$font-sizes: (12, 13, 14, 16, 18, 20, 22, 24, 32, 36);
$block-positions: ('absolute', 'relative', 'fixed');

// Grid columns.
$grid-columns: 12 !default;
$grid-gutter-width: 30px !default;

$percentages: (10, 20, 30, 40, 50, 60, 70, 80, 90, 100);

// Material color schemas

// TPSA
$md-flow-green: (
        50 : #edf7e9,
        100 : #d2ecc8,
        200 : #b4dfa3,
        300 : #96d27e,
        400 : #80c862,
        500 : #69be46,
        600 : #61b83f,
        700 : #56af37,
        800 : #4ca72f,
        900 : #3b9920,
        A100 : #ddffd4,
        A200 : #b4ffa1,
        A400 : #8bff6e,
        A700 : #76ff55,
        contrast: (
                50 : #000000,
                100 : #000000,
                200 : #000000,
                300 : #000000,
                400 : #000000,
                500 : #000000,
                600 : #000000,
                700 : #000000,
                800 : #ffffff,
                900 : #ffffff,
                A100 : #000000,
                A200 : #000000,
                A400 : #000000,
                A700 : #000000,
        )
);

// Marketplace
$md-flow-blue: (
        50 : #e0e8f0,
        100 : #b3c6da,
        200 : #80a0c1,
        300 : #4d7aa8,
        400 : #265e95,
        500 : #004182,
        600 : #003b7a,
        700 : #00326f,
        800 : #002a65,
        900 : #001c52,
        A100 : #85a2ff,
        A200 : #527cff,
        A400 : #1f55ff,
        A700 : #0542ff,
        contrast: (
                50 : #000000,
                100 : #000000,
                200 : #000000,
                300 : #ffffff,
                400 : #ffffff,
                500 : #ffffff,
                600 : #ffffff,
                700 : #ffffff,
                800 : #ffffff,
                900 : #ffffff,
                A100 : #000000,
                A200 : #ffffff,
                A400 : #ffffff,
                A700 : #ffffff,
        )
);

// TPIA
$md-flow-light-blue: (
        50 : #e4f1fa,
        100 : #bcddf2,
        200 : #8fc6e9,
        300 : #62afe0,
        400 : #409dd9,
        500 : #1e8cd2,
        600 : #1a84cd,
        700 : #1679c7,
        800 : #126fc1,
        900 : #0a5cb6,
        A100 : #e1eeff,
        A200 : #aed1ff,
        A400 : #7bb4ff,
        A700 : #62a6ff,
        contrast: (
                50 : #000000,
                100 : #000000,
                200 : #000000,
                300 : #000000,
                400 : #000000,
                500 : #ffffff,
                600 : #ffffff,
                700 : #ffffff,
                800 : #ffffff,
                900 : #ffffff,
                A100 : #000000,
                A200 : #000000,
                A400 : #000000,
                A700 : #000000,
        )
);

// TPAO
$md-flow-red: (
        50 : #fae1e4,
        100 : #f2b4bc,
        200 : #e9828f,
        300 : #e05062,
        400 : #d92b40,
        500 : #d2051e,
        600 : #cd041a,
        700 : #c70416,
        800 : #c10312,
        900 : #b6010a,
        A100 : #ffdfdf,
        A200 : #ffacad,
        A400 : #ff797b,
        A700 : #ff5f62,
        contrast: (
                50 : #000000,
                100 : #000000,
                200 : #000000,
                300 : #ffffff,
                400 : #ffffff,
                500 : #ffffff,
                600 : #ffffff,
                700 : #ffffff,
                800 : #ffffff,
                900 : #ffffff,
                A100 : #000000,
                A200 : #000000,
                A400 : #000000,
                A700 : #000000,
        )
);

// DG
$md-flow-orange: (
        50 : #fef3e6,
        100 : #fce1c0,
        200 : #facd96,
        300 : #f8b96c,
        400 : #f7aa4d,
        500 : #f59b2d,
        600 : #f49328,
        700 : #f28922,
        800 : #f07f1c,
        900 : #ee6d11,
        A100 : #ffffff,
        A200 : #fff0e7,
        A400 : #ffd1b4,
        A700 : #ffc19b,
        contrast: (
                50 : #000000,
                100 : #000000,
                200 : #000000,
                300 : #000000,
                400 : #000000,
                500 : #000000,
                600 : #000000,
                700 : #000000,
                800 : #000000,
                900 : #000000,
                A100 : #000000,
                A200 : #000000,
                A400 : #000000,
                A700 : #000000,
        )
);

// Plain colors.
$colors: (
        white: (
                100: #fefefe,
                80: #f2f2f2
        ),
        black: (
                100: #010101
        ),
        warning: (
                100: #FFB61E
        ),
        danger: (
                100: #F22613,
                80: #CF000F,
                60: #C3272B,
                40: #a94442,
                20: #f2dede
        ),
        success: (
                100: map.get($md-flow-green, 500)
        ),
        info: (
                100: #59ABE3
        ),
        gray: (
                200: #373a3c,
                100: #868686,
                80: #9d9d9d,
                60: #b6b6b6,
                40: #cecece,
                20: #e7e7e7
        ),
        tpsa: (
                100: map.get($md-flow-green, 500),
                20: map.get($md-flow-green, 100)
        ),
        tpma: (
                100: map.get($md-flow-orange, 500),
                20: map.get($md-flow-orange, 100)
        ),
        tpia: (
                100: map.get($md-flow-light-blue, 500),
                20: map.get($md-flow-light-blue, 100)
        ),
        tpao: (
                100: map.get($md-flow-red, 500),
                20: map.get($md-flow-red, 100)
        ),
        blue: (
                100: map.get($md-flow-blue, 500),
                80: #005eaa,
                30: #2e7ab7,
                20: map.get($md-flow-blue, 100)
        )
);

$light-border: rgba(0, 0, 0, 0.12);
$border-bottom-on-light: 1px solid $light-border;
