@use "sass:map";

@use "@angular/material" as mat;

@use "../functions" as *;
@use "../mixins" as *;

@mixin flow-theme-menus($theme) {
  html {
    --mat-menu-item-label-text-size: .8rem;
    --mat-menu-item-label-text-line-height: 1.25rem;
    --mat-menu-item-label-text-color: var(--text);
  }

  .mat-mdc-menu-panel {
    max-width: 250px !important;
  }

  .mat-mdc-menu-content {
    padding: 0 !important;
  }

  .mat-mdc-menu-item {
    background: var(--dropdown-bg-color);
    line-height: normal !important;
    display: inline-flex !important;
    float: left;

    .material-icons {
      font-size: rem(16);
      margin-right: 5px;
      position: relative;
      top: 3px;
    }

    &[disabled] {
      &, &::after {
        color: mat.get-theme-color($theme, 'foreground', 'disabled') !important;
      }
    }

    &.heading {
      font-weight: 500 !important;
      text-transform: uppercase !important;
      font-size: rem(11) !important;
    }

    &.divider {
      height: 1px !important;
      width: 100%;
      padding: 0 !important;
      border-bottom: 1px solid #efefef;
    }
  }

  .mat-mdc-menu-item .mat-icon-no-color,
  .mat-mdc-menu-item-submenu-trigger::after {
    color: mat.get-theme-color($theme, 'foreground', 'icon');
  }

  .mat-mdc-menu-item:hover,
  .mat-menu-item.cdk-program-focused,
  .mat-menu-item.cdk-keyboard-focused,
  .mat-menu-item-highlighted {
    &:not([disabled]) {
      background: var(--dropdown-bg-color-hover);
      color: var(--dropdown-color-hover);
    }
  }
}
