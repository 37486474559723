@use "functions" as *;

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/material-icons/MaterialIcons-Regular.eot); /* For IE6-8 */
  src: local('Material Icons'),
  local('MaterialIcons-Regular'),
  url(../fonts/material-icons/MaterialIcons-Regular.woff2) format('woff2'),
  url(../fonts/material-icons/MaterialIcons-Regular.woff) format('woff'),
  url(../fonts/material-icons/MaterialIcons-Regular.ttf) format('truetype');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: rem(24);  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

/* roboto-100 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  src: url('../fonts/roboto/roboto-v18-latin-100.eot'); /* IE9 Compat Modes */
  src: local('Roboto Thin'), local('Roboto-Thin'),
  url('../fonts/roboto/roboto-v18-latin-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/roboto/roboto-v18-latin-100.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/roboto/roboto-v18-latin-100.woff') format('woff'), /* Modern Browsers */
  url('../fonts/roboto/roboto-v18-latin-100.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/roboto/roboto-v18-latin-100.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-300 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/roboto/roboto-v18-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Roboto Light'), local('Roboto-Light'),
  url('../fonts/roboto/roboto-v18-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/roboto/roboto-v18-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/roboto/roboto-v18-latin-300.woff') format('woff'), /* Modern Browsers */
  url('../fonts/roboto/roboto-v18-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/roboto/roboto-v18-latin-300.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-300italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  src: url('../fonts/roboto/roboto-v18-latin-300italic.eot'); /* IE9 Compat Modes */
  src: local('Roboto Light Italic'), local('Roboto-LightItalic'),
  url('../fonts/roboto/roboto-v18-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/roboto/roboto-v18-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/roboto/roboto-v18-latin-300italic.woff') format('woff'), /* Modern Browsers */
  url('../fonts/roboto/roboto-v18-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/roboto/roboto-v18-latin-300italic.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-100italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 100;
  src: url('../fonts/roboto/roboto-v18-latin-100italic.eot'); /* IE9 Compat Modes */
  src: local('Roboto Thin Italic'), local('Roboto-ThinItalic'),
  url('../fonts/roboto/roboto-v18-latin-100italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/roboto/roboto-v18-latin-100italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/roboto/roboto-v18-latin-100italic.woff') format('woff'), /* Modern Browsers */
  url('../fonts/roboto/roboto-v18-latin-100italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/roboto/roboto-v18-latin-100italic.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/roboto/roboto-v18-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Roboto'), local('Roboto-Regular'),
  url('../fonts/roboto/roboto-v18-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/roboto/roboto-v18-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/roboto/roboto-v18-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../fonts/roboto/roboto-v18-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/roboto/roboto-v18-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/roboto/roboto-v18-latin-500.eot'); /* IE9 Compat Modes */
  src: local('Roboto Medium'), local('Roboto-Medium'),
  url('../fonts/roboto/roboto-v18-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/roboto/roboto-v18-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/roboto/roboto-v18-latin-500.woff') format('woff'), /* Modern Browsers */
  url('../fonts/roboto/roboto-v18-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/roboto/roboto-v18-latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-500italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 500;
  src: url('../fonts/roboto/roboto-v18-latin-500italic.eot'); /* IE9 Compat Modes */
  src: local('Roboto Medium Italic'), local('Roboto-MediumItalic'),
  url('../fonts/roboto/roboto-v18-latin-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/roboto/roboto-v18-latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/roboto/roboto-v18-latin-500italic.woff') format('woff'), /* Modern Browsers */
  url('../fonts/roboto/roboto-v18-latin-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/roboto/roboto-v18-latin-500italic.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/roboto/roboto-v18-latin-italic.eot'); /* IE9 Compat Modes */
  src: local('Roboto Italic'), local('Roboto-Italic'),
  url('../fonts/roboto/roboto-v18-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/roboto/roboto-v18-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/roboto/roboto-v18-latin-italic.woff') format('woff'), /* Modern Browsers */
  url('../fonts/roboto/roboto-v18-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/roboto/roboto-v18-latin-italic.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-700 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/roboto/roboto-v18-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Roboto Bold'), local('Roboto-Bold'),
  url('../fonts/roboto/roboto-v18-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/roboto/roboto-v18-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/roboto/roboto-v18-latin-700.woff') format('woff'), /* Modern Browsers */
  url('../fonts/roboto/roboto-v18-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/roboto/roboto-v18-latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-700italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  src: url('../fonts/roboto/roboto-v18-latin-700italic.eot'); /* IE9 Compat Modes */
  src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'),
  url('../fonts/roboto/roboto-v18-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/roboto/roboto-v18-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/roboto/roboto-v18-latin-700italic.woff') format('woff'), /* Modern Browsers */
  url('../fonts/roboto/roboto-v18-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/roboto/roboto-v18-latin-700italic.svg#Roboto') format('svg'); /* Legacy iOS */
}

.fw-400 {
  font-weight: 400 !important;
}
