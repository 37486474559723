//
// This file contains special styles for the standard einvoicing project.
// The id sps-einvoicing is added to the body tag by setBodyId() in env.service.ts
//

:root {
  --sps-deep-blue: #0079a5;
  --sps-pale-blue: #d9f2fb;
  --sps-pale-grey: #f6f6f6;
}

#sps-einvoicing {

  /* Dashboard */

  gridster {
    gridster-item {
      box-shadow: none;
      border: .0625rem solid #d2d4d4;
      border-radius: .1875rem;

      &.headless-widget {
        background: var(--sps-pale-grey);
      }

      &.transparent-widget {
        background: transparent;
        border: none;
      }

      &:hover {
        border-color: var(--sps-deep-blue);
      }
    }
  }
}

