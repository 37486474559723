@use "../functions" as *;
@use "../mixins" as *;

@mixin flow-theme-tiles($theme) {
  .tile {
    @include flexbox();

    align-items: center;
    flex-direction: column;
    margin-bottom: 15px;

    .material-icons,
    .flow-icon {
      font-size: rem(48);
      margin-bottom: 1.5rem;
      color: var(--primary);
    }

    .tile-inner {
      @include dflex(0, 0, 100%);
      @include borderRadius(8px);
      @include transition(all);

      background-color: var(--white);
      border: 1px solid var(--tile-border-color);
      padding: 3rem 2rem;
      font-size: .85rem;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;

      &:hover {
        border: 1px solid var(--tile-border-color-hover);
      }

      h6 {
        font-weight: 400;
        color: var(--primary);
      }

      p {
        margin: .5rem auto;
        max-width: 90%;
        font-size: rem(12);
        display: block;
      }
    }

    &.flat-tile {
      .flow-icon {
        color: var(--text);
        @include transition(color);
      }
      .tile-inner {
        h6 {
          color: fcolor(gray, 100);
        }
      }
    }

    &.tile-with-link {
      cursor: pointer;

      &:hover,
      &:focus {
        .tile-inner {
          @include boxShadow(0 4px 4px 0 rgba(0,0,0,0.04), 0 1px 16px 0 rgba(0,0,0,0.16), 0 2px 4px 0 rgba(0,0,0,0.08));

          background: var(--primary-hover);
        }

        h6,
        p,
        .material-icons,
        .flow-icon {
          color: var(--white);
        }
      }

      &:focus,
      &:active {
        outline: none;
      }

      .description {
        color: var(--black);

        &:hover {
          color: var(--white);
        }
      }
    }
  }
}
