@use "mixins" as *;

@include flow-schemas-colors();

.color-primary {
  color: var(--primary) !important;
}

.color-danger {
  color: var(--danger) !important;
}

.color-success {
  color: var(--success) !important;
}

.color-text {
  color: var(--text) !important;
}

.color-black {
  color: var(--black) !important;
}

.color-white {
  color: var(--white) !important;
}

.color-infotext {
  color: var(--info-text-color);
}

.bg-primary {
  background: var(--primary);
}

.bg-danger {
  background: var(--danger);
}

.bg-success {
  background: var(--success);
}
